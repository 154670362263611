<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <sidebar
      :store-module-name="STORE_MODULE_NAME"
      :is-sidebar-active.sync="isSidebarActive"
      :show.sync="show"
      :data="sidebarData"
    />
    <modal-stock
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalActive"
      :show.sync="show"
      :data="modalData"
    />
    <image-modal-stock
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isImageStockModalActive"
      :show.sync="show"
      :data="modalData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Show') }}</label>
              <label
                class="text-muted ml-2 mr-2"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}</label>
              <label for="">{{ $t('entries') }}</label>

              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="select-size-sm"
                @input="pageSize"
              />
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <label>{{ $t('Category') }}</label>
              <v-select
                v-model="categories"
                multiple
                size="sm"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="respCategoryData"
                :reduce="respCategoryData => respCategoryData._id"
                :clearable="true"
                class="select-size-sm"
                @input="categorySearch"
              />
            </b-col>
            <b-col
              cols="12"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  class="d-flex mr-2"
                  @click="importProduct()"
                >
                  <span class="align-middle">{{ $t('Import') }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex"
                  @click="sidebarAddNew()"
                >
                  <feather-icon icon="PlusIcon" />
                  <!-- <span class="align-middle">Add Users</span> -->
                </b-button>
                <!-- <b-form-file
                @change="importFileJson"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".json"
              /> -->
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refUserListTable"
          striped
          responsive
          class="position-relative"
          :items="respData"
          style="height: 60vh;"
          :fields="tableColumns"
          primary-key="_id"
          show-empty
          :empty-text="$t('No matching records found')"
        >
          <!-- Column: Images -->
          <template #cell(images)="data">
            <b-row>
              <b-img
                v-if="data.item.images.length > 0"
                :src="data.item.images[0]"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
              <b-img
                v-else
                :src="require('@/assets/images/blank/no_image.png')"
                fluid
                thumbnail
                width="100px"
                :alt="'Users_' + data.item._id"
              />
            </b-row>
          </template>
          <template #cell(name)="data">
            <p>
              {{
                data.item.optionNameTranslate === 'multiple'
                  ? showFromCurrentLanguage(data.item.nameTranslate)
                  : data.item.name
              }}
            </p>
          </template>

          <!-- Column: Images -->
          <template #cell(stock)="data">
            <b-row>
              <p>
                {{
                  data.item.stockStatus
                    ? data.item.stock
                    : $t('The stock system has not yet been opened')
                }}
              </p>
            </b-row>
          </template>
          <template #cell(secondHand)="data">
            <feather-icon
              v-if="data.item.secondHand === true"
              color="green"
              icon="CheckCircleIcon"
              size="18"
            />
            <feather-icon
              v-if="data.item.secondHand === false"
              color="red"
              icon="XCircleIcon"
              size="18"
            />
          </template>
          <!-- Column: Images -->
          <template #cell(productCategory)="data">
            <b-row>
              <p
                v-for="(itemCategory, index) in data.item.productCategory"
                :key="index"
                class="align-text-bottom line-height-1"
              >
                {{ showFromCurrentLanguage(itemCategory['name']) }} ,
              </p>
            </b-row>
          </template>
          <template #cell(affiliateProductGroupId)="data">
            <p >
              {{
               data.item.affiliateProductGroupId?
                showFromCurrentLanguage(data.item.affiliateProductGroupId.name)
                : '-'
              }}
            </p>
          </template>
          <template #cell(bonus)="data">
            <feather-icon
              v-if="data.item.bonus === true"
              color="green"
              icon="CheckCircleIcon"
              size="18"
            />
            <feather-icon
              v-if="data.item.bonus === false"
              color="red"
              icon="XCircleIcon"
              size="18"
            />
          </template>
          <!-- Column: Images -->
          <template #cell(updated)="data">
            <b-row>
              <p>{{ data.item.updated | formatDateTime }}</p>
            </b-row>
          </template>
          <!-- Column: Images -->
          <template #cell(created)="data">
            <b-row>
              <p>{{ data.item.created | formatDateTime }}</p>
            </b-row>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="openManageCategory(data.item)">
                <feather-icon icon="ListIcon" />
                <span class="align-middle ml-50">{{ $t('Category') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.stockStatus && data.item.stockBy === 'option'"
                @click="openOptionStock(data.item)"
              >
                <feather-icon icon="ShoppingBagIcon" />
                <span class="align-middle ml-50">{{ $t('Stock') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.stockStatus && data.item.stockBy === 'product'"
                @click="openProductStock(data.item)"
              >
                <feather-icon icon="ShoppingBagIcon" />
                <span class="align-middle ml-50">{{ $t('Option') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="openProductImageStock(data.item)">
                <feather-icon icon="ImageIcon" />
                <span class="align-middle ml-50">{{
                  $t('image of product variant')
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="sidebarEdit(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="openEditProduct(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('Edit Html') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteData(data.item._id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                class="text-muted"
              >{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                    ? currentPage * perPage
                    : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="queriedItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="nextPage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/product'
import storeModuleShiping from '@/store/services/eCommerce/shiping'
import storeModuleAffiliateProductGroup from '@/store/services/eCommerce/affiliateProductGroup'
import debounce from 'debounce'
import Sidebar from './DataSidebar.vue'
import ModalStock from './stock/ModalStock.vue'
import ImageModalStock from './stock/ImageModalStock.vue'

const STORE_MODULE_NAME = 'product'
const STORE_MODULE_NAME_SHIPING = 'shiping'
const STORE_MODULE_NAME_AFFILIATE_PRODUCT_GROUP = 'affiliateProductGroup'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    Sidebar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    ModalStock,
    ImageModalStock,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      categories: [],
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      isSidebarActive: false,
      sidebarData: {},
      isModalActive: false,
      isImageStockModalActive: false,
      modalData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'images', label: this.$t('Image'), sortable: false },
        { key: 'name', label: this.$t('Name'), sortable: false },
        { key: 'stock', label: this.$t('Stock'), sortable: false },
        { key: 'secondHand', label: this.$t('Second Hand'), sortable: false },
        {
          key: 'productCategory',
          label: this.$t('Product Category'),
          sortable: false,
        },

        { key: 'affiliateProductGroupId', label: this.$t('Affiliate'), sortable: false },
        { key: 'bonus', label: this.$t('Bonus'), sortable: false },
        { key: 'updated', label: this.$t('Updated'), sortable: false },
        { key: 'created', label: this.$t('Create'), sortable: false },
        // { key: 'lastName', label: this.$t('LastName'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    respCategoryData() {
      return store.state[STORE_MODULE_NAME].respCategoryData != null
        ? store.state[STORE_MODULE_NAME].respCategoryData.map(e => ({
          _id: e._id,
          name: this.showFromCurrentLanguage(e.name),
        }))
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_SHIPING)) store.registerModule(STORE_MODULE_NAME_SHIPING, storeModuleShiping)
    if (!store.hasModule(STORE_MODULE_NAME_AFFILIATE_PRODUCT_GROUP)) store.registerModule(STORE_MODULE_NAME_AFFILIATE_PRODUCT_GROUP, storeModuleAffiliateProductGroup)

    this.get()
    this.getCategory()
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_SHIPING)) store.unregisterModule(STORE_MODULE_NAME_SHIPING)
    if (store.hasModule(STORE_MODULE_NAME_AFFILIATE_PRODUCT_GROUP)) store.unregisterModule(STORE_MODULE_NAME_AFFILIATE_PRODUCT_GROUP)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_SHIPING, STORE_MODULE_NAME_AFFILIATE_PRODUCT_GROUP }
  },
  methods: {

    getCategory() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/getCategory`, { searchQuery: '' })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
        categories: this.categories,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
        //
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    openProductStock(item) {
      this.modalData = item
      this.isModalActive = true
    },
    openProductImageStock(item) {
      this.modalData = item
      this.isImageStockModalActive = true
    },
    openOptionStock(data) {
      this.show = true

      this.$bvModal
        .msgBoxConfirm(
          `${this.$t('Do you want to open')} ${this.$t('Product Stock')}?`,
          {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'success',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          },
        )
        .then(value => {
          this.show = false

          if (value === true) {
            this.$router.push({ path: `/e-commerce/product/stock/${data._id}` })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    openEditProduct(data) {
      this.show = true

      this.$bvModal
        .msgBoxConfirm(
          `${this.$t('Do you want to edit')} ${this.$t('Product')}?`,
          {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'success',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          },
        )
        .then(value => {
          this.show = false

          if (value === true) {
            this.$router.push({ path: `/e-commerce/product/view/${data._id}` })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    openManageCategory(data) {
      this.show = true

      this.$bvModal
        .msgBoxConfirm(
          `${this.$t('Do you want to manage')} ${this.$t('Category')}?`,
          {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'success',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          },
        )
        .then(value => {
          this.show = false

          if (value === true) {
            this.$router.push({
              path: `/e-commerce/product/managecategory/${data._id}`,
            })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    importProduct() {
      this.$router.push({ name: 'e-commerce-product-import' })
    },
    typing(value) {
      this.searchQuery = value
      this.get()
    },
    sidebarAddNew() {
      this.sidebarData = {}
      this.isSidebarActive = true
    },
    sidebarEdit(item) {
      this.sidebarData = item
      this.isSidebarActive = true
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, id)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.show = false
              })
              .catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      this.get()
    },
    nextPage(page) {
      this.currentPage = page
      this.get()
    },
    categorySearch() {
      this.get()
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
